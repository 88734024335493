/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create();

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export const url = (path: string) => {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_ENDPOINT_TEST + path;
    }
    return process.env.REACT_APP_ENDPOINT_PROD + path;
};

export default axiosServices;
